<template>
  <section class="page-container">
    <div ref="toolbar" class="toolbar">
      <el-form :inline="true" :model="exportCard">
        <el-form-item>
          <el-select v-model="exportCard.status" placeholder="请选择状态">
            <el-option
              v-for="item in statusOption"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select v-model="exportCard.cardType" placeholder="请选择卡密类型">
            <el-option
              v-for="item in cardTypeOption"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="exportCard.cardType === '1'">
          <el-select v-model="exportCard.denomination" placeholder="请选择卡密参数">
            <el-option
              v-for="item in scoreDenomination"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="exportCard.cardType === '2'">
          <el-select v-model="exportCard.denomination" placeholder="请选择卡密参数">
            <el-option
              v-for="item in memberDenomination"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="exportCard.cardType === '2'">
          <el-select v-model="exportCard.memberTime" placeholder="请选择卡密时长">
            <el-option
              v-for="item in memberTime"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="exportCard.cardType != '3'">
          <el-select v-model="exportCard.delimiter" placeholder="请选择卡密间隔">
            <el-option
              v-for="item in delimiterOption"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-date-picker
            v-model="exportCard.startDate" placeholder="起始时间" value-format="timestamp"
            dateType="time">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="list"> 查询 </el-button>
        </el-form-item>
      </el-form>
    </div>
    <div>
      <el-table
        :data="cards"
        v-loading = "loading"
        element-loading-background = "rgba(0, 0, 0, 0.5)"
        element-loading-text = "数据正在加载中"
        element-loading-spinner = "el-icon-loading"
        style="width: 100%">
        <el-table-column
          prop="card"
          label="卡密">
        </el-table-column>
      </el-table>
    </div>
  </section>
</template>

<script>
export default {
  data(){
    return{
      cards:[],
      loading:false,
      exportCard:{
        cardType:'',
        status:'',
        denomination:'',
        delimiter:'',
        startDate:'',
        memberTime:''
      },
      cardTypeOption:[
        {
          label:'灵气卡',
          value:"1"
        },
        {
          label:'会员卡',
          value:"2"
        },
        {
          label:'邀请码',
          value:"3"
        }
      ],
      scoreDenomination:[
        {
          label:'100灵气',
          value:"100"
        },
        {
          label:'150灵气',
          value:"150"
        },
        {
          label:'300灵气',
          value:"300"
        },
        {
          label:'500灵气',
          value:"500"
        },
        {
          label:'1000灵气',
          value:"1000"
        }
      ],
      memberDenomination:[
        {
          label:'赞助会员',
          value:"5"
        },
        {
          label:'黄金会员',
          value:"1"
        },
        {
          label:'白金会员',
          value:"2"
        }
      ],
      memberTime:[
        {
          label:'月卡',
          value:"31"
        },
        {
          label:'年卡',
          value:"365"
        },
      ],
      statusOption:[
        {
          label:'未使用',
          value:"0"
        },
        {
          label:'已使用',
          value:"1"
        }
      ],
      delimiterOption:[
        {
          label:'使用空格',
          value:"1"
        },
        {
          label:'卡号:XXX 卡密:XXX',
          value:"2"
        },
        {
          label:'使用-',
          value:"3"
        }
      ],
    }
  },
  mounted(){

  },
  methods:{
    async list(){
      let me = this
      this.loading = true
      try{
        let list = await this.axios.form("/api/admin/card/export",me.exportCard)
        me.cards = list
      }catch (e){
        me.$notify.error({ title: "错误", message: e.message });
      }
      this.loading = false
    },
  }
}
</script>

<style scoped>

</style>
